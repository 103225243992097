import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../components/Logo";
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LanguageSelector from "../../components/LanguageSelector";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const MainStyle = styled("div")(({ theme }) => ({
  maxHeight: "70px",
  backgroundColor: theme.palette.primary.darker,
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  padding: "0 16px",
  justifyContent: "space-between" 
}));

export default function KioskHeader() {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  );
  const country = "Türkiye"; // Hardcoded country, replace with dynamic logic if needed

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(
        new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <MainStyle>
      <Box
        component={RouterLink}
        to="/"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
          marginLeft: "-15px"
        }} 
      >
        <Logo sx={{
          width: 150,
          height: 60
        }}/>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 3 }}>
          <AccessTimeIcon sx={{ marginRight: 0.5 }} /> 
          <span>{currentTime}</span> - <span>{country}</span>
        </Box>
        {/* <LanguageSelector /> */}
      </Box>
    </MainStyle>
  );
}
