const urls = {

    get_session: "/core/v1/check/get-session-type",

    //BOOKMAKERS
    list_bookmakers : "/core/v1/get-bookmakers",

    //SPORTS
    list_sports : "/core/v1/get-sports",

    //CURRENCIES
    list_currencies : "/core/v1/get-currencies",

    //AUTHENTICATION
    
    store_login: "/pa/st/store/v1/auth/access-token",
    partition_login: "/pa/partition/v1/auth/access-token",
    platform_login: "/pl/platform/v1/auth/access-token",
    distributor_login: "/pa/dt/distributor/v1/auth/access-token",
    kiosk_login: "/pa/ki/kiosk/v1/auth/access-token",

    //PARTITION

    list_partitions : "/pl/platform/v1/partitions/list-partitions",
    get_partition: (id) => {
        return `/pl/platform/v1/partitions/get-parztition/${id}`
    },

    create_partition: "/pl/platform/v1/partitions/create-partition",

    update_partition: (id) => {
        return `/pl/platform/v1/partitions/update-partition/${id}`
    },

    get_sport_bookmaker_mappings: (id) => {
        return `/pl/platform/v1/partitions/list-sport-bookmaker-mappings/${id}`
    },

    update_sport_bookmaker_mapping: (id) => {
        return `/pl/platform/v1/partitions/update-sport-bookmaker-mapping/${id}`
    },

    //DISTRIBUTORS

    list_distributors: "/pa/partition/v1/distributors/list-distributor",

    get_distributor: (id) => {
        return `/pa/partition/v1/distributors/get-distributor/${id}`
    },

    create_distributor: "/pa/partition/v1/distributors/create-distributor",

    update_distributor: (id) => {
        return `/pa/partition/v1/distributors/update-distributor/${id}`
    },

    delete_distributor: (id) => {
        return `/pa/partition/v1/distributors/delete-distributor/${id}`
    },

    // LİST PARTITION USERS

    list_partitions_users: "/pa/partition/v1/partition-users/list-users",

    get_partition_users: (id) => {
        return `/pa/partition/v1/partition-users/get-user/${id}`
    },

    create_partition_users: "/pa/partition/v1/partition-users/create-user",

    update_partition_users: (id) => {
        return `/pa/partition/v1/partition-users/update-user/${id}`
    },

    update_partition_users_password: (id) => {
        return `/pa/partition/v1/partition-users/change-partition-user-password/${id}`
    },

    delete_partition_users: (id) => {
        return `/pa/partition/v1/partition-users/delete-partition-user/${id}`
    },

    // LİST DISTRIBUTOR USERS

    list_distributor_users: "/pa/dt/distributor/v1/distributor-users/list-users",

    get_distributor_users: (id) => {
        return `/pa/dt/distributor/v1/distributor-users/get-user/${id}`
    },

    create_distributor_users: "/pa/dt/distributor/v1/distributor-users/create-user",

    update_distributor_users: (id) => {
        return `/pa/dt/distributor/v1/distributor-users/update-user/${id}`
    },

    update_distributor_users_password: (id) => {
        return `/pa/dt/distributor/v1/distributor-users/change-distributor-user-password/${id}`
    },

    delete_distributor_users: (id) => {
        return `/pa/dt/distributor/v1/distributor-users/delete-distributor-user/${id}`
    },

    // LİST STORE USERS

    list_store_users: "/pa/st/store/v1/store-users/list-users",

    get_store_users: (id) => {
        return `/pa/st/store/v1/store-users/get-user/${id}`
    },

    create_store_users: "/pa/st/store/v1/store-users/create-user",

    update_store_users: (id) => {
        return `/pa/st/store/v1/store-users/update-user/${id}`
    },

    update_store_users_password: (id) => {
        return `/pa/st/store/v1/store-users/change-store-user-password/${id}`
    },

    delete_store_users: (id) => {
        return `/pa/st/store/v1/store-users/delete-store-user/${id}`
    },


    //Football Odds
    list_football_odds : "/pa/ki/kiosk/v1/odds/list-odds",
    
}   


export default urls;