import React from 'react';
import { Card, CardContent, Typography, Grid, Box, Chip } from '@mui/material';
import { SportsSoccer } from '@mui/icons-material';

export default function GeneralOdds({ data }) {
  const { league, fixture, bet } = data;
  /* const matchWinnerBet = bets.find(bet => bet.name === "Match Winner"); */

  return (
    <Card sx={{ maxWidth: 350, mx: 'auto', my: 2, boxShadow: 2 }}>
      <CardContent sx={{ p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Box display="flex" alignItems="center">
            <SportsSoccer fontSize="small" sx={{ mr: 0.5 }} />
            {/* <Typography variant="caption" color="text.secondary">
                {league.country + " - " + league.name}
            </Typography> */}
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: 1
            }}>
                <Typography variant="caption" color="text.primary">
                {league.country}
                </Typography> 
                <Typography variant="caption" color="text.secondary">
                {league.name}
                </Typography> 

            </Box>
          </Box>
          <Typography variant="caption" color="text.secondary">
            {new Date(fixture.date).toLocaleString('en-US', { 
              month: 'numeric', 
              day: 'numeric', 
              year: 'numeric', 
              hour: 'numeric', 
              minute: 'numeric', 
              hour12: true 
            })}
          </Typography>
        </Box>
        
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
          <Typography variant="body1" fontWeight="medium">{fixture.home}</Typography>
          <Typography variant="caption" color="text.secondary">vs</Typography>
          <Typography variant="body1" fontWeight="medium">{fixture.away}</Typography>
        </Box>
        
        {bet && (
          <Grid container spacing={1} justifyContent="center">
            {bet.values.map((value, index) => (
              <Grid item key={index}>
                <Chip
                  label={
                    <Box>
                      <Typography variant="caption" display="block">{value.value}</Typography>
                      <Typography variant="body2" fontWeight="bold">{value.odd}</Typography>
                    </Box>
                  }
                  variant="outlined"
                  sx={{ minWidth: 70 }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}