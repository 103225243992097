import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, FormControlLabel, Checkbox, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useTranslation } from "react-i18next";

const FilterComponent = ({ setFilters }) => {
  const { t } = useTranslation();  
  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedLeagues, setSelectedLeagues] = useState([]);

  useEffect(() => {
    setFilters({
      sports: selectedSports,
      countries: selectedCountries,
      leagues: selectedLeagues,
    });
  }, [selectedSports, selectedCountries, selectedLeagues, setFilters]);

  const handleSportsChange = (sport) => {
    setSelectedSports((prev) =>
      prev.includes(sport) ? prev.filter((s) => s !== sport) : [...prev, sport]
    )
  };

  const handleCountriesChange = (country) => {
    setSelectedCountries((prev) =>
      prev.includes(country) ? prev.filter((s) => s !== country) : [...prev, country]
    );
  };

  const handleLeaguesChange = (league) => {
    setSelectedLeagues((prev) =>
      prev.includes(league) ? prev.filter((s) => s !== league) : [...prev, league]
    );
  };

  return (
    <div
      style={{
        padding: "10px",
        width: "100%",
        maxWidth: "300px",
        backgroundColor: "#F2F2F2",
        minHeight: "47rem",
        position: "relative",
        borderRadius: "10px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "8px", margin:'1rem' }}>
        <FilterAltOutlinedIcon style={{ fontSize: "30px" }} />
        <Typography variant="h6">{t("Filter By")}</Typography> 
      </div>
      
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t("sports")}</Typography> 
        </AccordionSummary>
        <AccordionDetails>
          {[
            "Football",
            "Basketball",
            "Volleyball",
            "Tennis",
            "Box",
            "Rugby",
          ].map((sport) => (
            <FormControlLabel
              key={sport}
              control={
                <Checkbox
                  checked={selectedSports.includes(sport)}
                  onChange={() => handleSportsChange(sport)}
                />
              }
              label={t(sport)} 
            />
          ))}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t("Countries")}</Typography> 
        </AccordionSummary>
        <AccordionDetails>
          {[
            "USA",
            "Turkey",
            "France",
            "Spain",
            "Italy",
            "UK",
          ].map((country) => (
            <FormControlLabel
              key={country}
              control={
                <Checkbox
                  checked={selectedCountries.includes(country)}
                  onChange={() => handleCountriesChange(country)}
                />
              }
              label={t(country)} 
            />
          ))}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t("Leagues")}</Typography> 
        </AccordionSummary>
        <AccordionDetails>
          {[
            "Premier League",
            "NBA",
            "La Liga",
            "Serie A",
          ].map((league) => (
            <FormControlLabel
              key={league}
              control={
                <Checkbox
                  checked={selectedLeagues.includes(league)}
                  onChange={() => handleLeaguesChange(league)}
                />
              }
              label={t(league)} 
            />
          ))}
        </AccordionDetails>
      </Accordion>

      {/* Apply Filters Button */}
      <Button
        variant="contained"
        color="accent"
        fullWidth
        style={{ marginTop: "20px" }}
      >
        {t("applyFilters")} 
      </Button>
    </div>
  );
};

export default FilterComponent;
