import React from 'react';
import { Chip, Typography } from '@mui/material';

const SelectedFilters = ({ filters }) => {
  const { sports, countries, leagues, } = filters;

  return (
    <div style={{ margin: '20px' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
      <Typography variant="subtitle1">Selected Filters:</Typography>
        {sports.length > 0 && sports.map((sport, index) => (
          <Chip key={index} label={sport} />
        ))}
        {countries.length > 0 && countries.map((country, index) => (
          <Chip key={index} label={country} />
        ))}
        {leagues.length > 0 && leagues.map((league, index) => (
          <Chip key={index} label={league} />
        ))}
      </div>
    </div>
  );
};

export default SelectedFilters;
