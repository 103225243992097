import { Box, Grid} from "@mui/material";

import { useEffect, useState } from "react";
import SelectedFilters from "../../layouts/kiosk/SelectedFilters";
import FilterComponent from "../../layouts/kiosk/Filter";
import { OddsService } from "../../api/services";
import GeneralOdds from "../../components/GeneralOdds";

export default function LandingPage() {
  const [filters, setFilters] = useState({
    sports: [],
    countries: [],
    leagues: [],
  });

  const [odds, setOdds] = useState([]);


  const fetchFootballOdds = () => {
    let params = {
      start_date  : "2024-10-12T10:45:30Z",
      end_date  : "2024-10-12T15:45:30Z"
    }
    OddsService.listGeneralFootballOdds(params)
      .then((response) => {
        if(response.status === 200){
          setOdds(response.data.items);

        }else{
          throw new Error("failed to fetch odds")
        }
      }).catch((err) => {
        console.error(err);
      })
  }

  useEffect(() => {
    fetchFootballOdds();
  }, [])


  return (
    <>
      {/* <Box>
        <SelectedFilters filters={filters} />
        <FilterComponent setFilters={setFilters} />
      </Box> */}
      <Grid container spacing={2}>
        <Grid item xs={3} md={3} /* sx={{border: "1px solid yellow"}} */>
          <Box>
            <SelectedFilters filters={filters} />
            <FilterComponent setFilters={setFilters} />
          </Box>
        </Grid>
        <Grid item xs={6} md={6} /* sx={{border: "1px solid red"}} */>
          {
            odds?.map((odd) => {
              return ( 
                <GeneralOdds data={odd}/>
              )
            })
          }
        </Grid>
        <Grid item xs={3} md={3} /* sx={{border: "1px solid blue"}} */>
          Coupon Container
        </Grid>

      </Grid>
    </>
  );
}
