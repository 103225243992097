import React from "react";
import DetailedOdds from "../../components/DetailedOdds";
import GeneralOdds from "../../components/GeneralOdds";

export default function KioskTest() {
  const mockBetsData = [
    {
      league: {
        id: 117,
        name: "Super Lig",
        country: "Turkey",
        logo: "https://media.api-sports.io/football/leagues/116.png",
        flag: "https://media.api-sports.io/flags/by.svg",
        season: 2020,
      },
      fixture: {
        id: 326090,
        timezone: "UTC",
        date: "2020-05-15T15:00:00+00:00",
        timestamp: 1589554800,
        home: "Galatasaray",
        away: "Fenerbahce",
      },
      bets: [
        {
          id: 1,
          name: "Match Winner",
          values: [
            {
              value: "Away",
              odd: "2.95",
            },
            {
              value: "Draw",
              odd: "2.95",
            },
            {
              value: "Home",
              odd: "2.50",
            },
          ],
        },
      ],
    },
    {
      league: {
        id: 116,
        name: "Vysshaya Liga",
        country: "Belarus",
        logo: "https://media.api-sports.io/football/leagues/116.png",
        flag: "https://media.api-sports.io/flags/by.svg",
        season: 2020,
      },
      fixture: {
        id: 326090,
        timezone: "UTC",
        date: "2020-05-15T15:00:00+00:00",
        timestamp: 1589554800,
      },
      bets: [
        {
          id: 1,
          name: "Match Winner",
          values: [
            {
              value: "Away",
              odd: "2.95",
            },
            {
              value: "Draw",
              odd: "2.95",
            },
            {
              value: "Home",
              odd: "2.50",
            },
          ],
        },
      ],
    },
  ];

  return (
    <div>
      {mockBetsData.map((fixtureData) => {
        return (
          <>
            <GeneralOdds data={fixtureData} />
          </>
        );
      })}
    </div>
  );
}
