import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import FilterComponent from "./Filter";
import SelectedFilters from "./SelectedFilters";
import KioskHeader from "./KioskHeader";

const KioskRootStyle = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  overflow: "hidden",
});

export default function KioskLayout({ isAdminLayout = false }) {


  return (
    <KioskRootStyle>
      {/* Header Component */}
      <KioskHeader />
      <Outlet />

    </KioskRootStyle>
  );
}
